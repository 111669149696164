import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { Move, Users } from 'react-feather'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  description?: string
  image?: ImageProps
  pax?: string
  services?: ServiceProps[]
  size?: string
  title?: string
}

export const Room = memo(function Room({
  description,
  image,
  pax,
  services,
  size,
  title,
}: Props) {
  return (
    <Container row wrap stretch>
      <LeftSide className="room-text" dial={4}>
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
        {pax || size ? (
          <Info row wrap>
            {size ? (
              <Size dial={4} row>
                <Move />
                {size}
              </Size>
            ) : null}
            {pax ? (
              <Pax dial={4} row>
                <Users />
                {pax}
              </Pax>
            ) : null}
          </Info>
        ) : null}
        {services && services.length > 0 ? (
          <Services row wrap space="between">
            {services.map((item, index) => (
              <Service key={index} {...item} />
            ))}
          </Services>
        ) : null}
      </LeftSide>
      <RightSide>
        {image ? <LazyLoadImage effect="blur" {...image} /> : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)``

const LeftSide = styled(FlexBox)`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 3.75rem 7.222vw;

  @media (max-width: 1199px) {
    padding: 2.5rem 1.875rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
    order: 2;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  letter-spacing: 0.0563rem;
  line-height: 2.25rem;
  text-transform: uppercase;
`

const Info = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  white-space: nowrap;
  svg {
    width: auto;
    height: 1.5rem;
    margin-right: 0.75rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralDark1};
    stroke-width: 1.5;
  }
`

const Size = styled(FlexBox)`
  margin-top: 1.125rem;
  margin-right: 2.1875rem;
`

const Pax = styled(FlexBox)`
  margin-top: 1.125rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.875rem;
  margin: 0.375rem 0 0.75rem;
`

const RightSide = styled.div`
  width: 50%;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  padding-bottom: 43%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }

  @media (max-width: 767px) {
    padding-bottom: 73%;
  }
`

const Services = styled(FlexBox)`
  width: 100%;
  margin-top: 2.1875rem;

  @media (max-width: 1023px) {
    margin-top: 1.25rem;
  }
`
