import styled from '@emotion/styled'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

import { Props as RoomProps, Room } from './Room'

export interface Props {
  rooms: RoomProps[]
}

export const MeetingRooms = memo(function MeetingRooms({ rooms }: Props) {
  if (rooms.length < 1) {
    return null
  }

  return (
    <Container>
      {rooms.map((item, index) => (
        <Fade key={index} bottom distance="3.75rem">
          <Room {...item} />
        </Fade>
      ))}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 7.5rem 10vw;
  > div {
    margin-top: 4.1875rem;
    &:first-of-type {
      margin-top: 0;
    }
    &:nth-of-type(even) {
      .room-text {
        order: 2;
      }
    }
  }

  @media (max-width: 1199px) {
    padding: 3.75rem 8.454vw;
    > div {
      margin-top: 3rem;
    }
  }

  @media (max-width: 767px) {
    padding-right: 0;
    padding-left: 0;
  }
`
