import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo, useState } from 'react'
import { ChevronDown } from 'react-feather'

export interface Props {
  languageCode: string
  services?: string[]
  title?: string
}

export const ServicesContent = memo(function ServicesContent({
  languageCode,
  services,
  title,
}: Props) {
  if (!services) {
    return null
  }

  const [expandedServices, setExpandedServices] = useState(false)

  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      <Services
        className={
          services?.length > 6 && !expandedServices ? 'compact' : undefined
        }
        row
        wrap
      >
        {services.map((item, index) => (
          <Service key={index}>
            {
              // @ts-ignore
              item.label
            }
          </Service>
        ))}
      </Services>
      {services?.length > 6 ? (
        <Expander
          className={expandedServices ? 'hidden' : undefined}
          onClick={() => setExpandedServices(true)}
        >
          {useVocabularyData('show-all', languageCode)}
          <ChevronDown />
        </Expander>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  padding: 5rem 0 5rem 10vw;
  position: relative;
  &:before {
    content: '';
    width: 100vw;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    position: absolute;
    top: 0;
    left: 0;
  }
  &:after {
    content: '';
    width: 21.944vw;
    height: 20.833vw;
    max-height: 18.75rem;
    background: url('/wave.svg') no-repeat;
    background-size: cover;
    opacity: 0.16;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1199px) {
    padding-left: 8.454vw;
  }

  @media (max-width: 767px) {
    padding: 3rem 8.454vw;
    &:after {
      display: none;
    }
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.875rem;
  margin-bottom: 2.1875rem;
  position: relative;
  z-index: 2;
`

const Services = styled(FlexBox)`
  @media (max-width: 767px) {
    &.compact {
      height: 18.75rem;
      overflow: hidden;
      position: relative;
      &:after {
        content: '';
        width: 100%;
        height: 6.25rem;
        background: linear-gradient(
          ${rgba(theme.colors.variants.neutralDark1, 0)},
          ${rgba(theme.colors.variants.neutralDark1, 1)}
        );
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }
`

const Service = styled.div`
  width: calc(50% - 4.931vw);
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.875rem;
  letter-spacing: 0.0175rem;
  line-height: 1.375rem;
  margin: 0 4.931vw 1.25rem 0;
  padding-left: 1.0625rem;
  position: relative;
  &:before {
    content: '';
    width: 0.3125rem;
    height: 0.3125rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    border-radius: 50%;
    position: absolute;
    top: 0.625rem;
    left: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
  }
`

const Expander = styled.div`
  display: none;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.0875rem;
  line-height: 0.9375rem;
  margin-top: 1.875rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  z-index: 2;
  &.hidden {
    display: none;
  }
  svg {
    display: block;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin: auto;
  }

  @media (max-width: 767px) {
    display: block;
  }
`
