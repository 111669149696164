import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  icon?: string
  label?: string
}

export const Service = memo(function Service({ icon, label }: Props) {
  return (
    <Container dial={4} row>
      {icon ? <SVG src={icon} width="28" height="20" alt={label} /> : null}
      {label ? <Label>{label}</Label> : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: calc(50% - 1.125rem);
  margin-top: 1.875rem;
`

const SVG = styled.img`
  width: auto;
  height: auto;
  max-width: 1.75rem;
  max-height: 1.25rem;
  margin-right: 0.75rem;
`

const Label = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
`
